import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";
import { mutate } from "swr";
import { CheckboxIcon } from "@aureskonnect/react-ui";

import { store } from "@components/VerticalLayout/store";
import {
    setIsDataUpdated,
    setIsHistoryShopsModalOpened,
    setSelectedPaymentMethods,
    setShopsNumberActive,
    setShopsNumberArchived,
    setShopsPaymentMethodsData,
    setShopsSelectedRow,
    store as dataStore,
    setLocalShop,
    setAssociatedPaymentMethods,
    setIsLoading,
    setKey,
} from "../store";
import { generalConfigStore, setItemsDisable } from "@store";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import { CustomSideFilter } from "./CustomSideFilter";
import { PaymentModalAssociateModal } from "./PaymentModalAssociateModal";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import PaymentMethodRow from "./PaymentMethodRow";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ShopsPaymentMethodType = {
    isArchivedPaymentMethodsClicked: boolean;
    setIsArchivedPaymentMethodsClicked: Function;
    setToastLimit: Function;
};
export default function ShopsPaymentMethod({
    isArchivedPaymentMethodsClicked,
    setIsArchivedPaymentMethodsClicked,
    setToastLimit,
}: ShopsPaymentMethodType) {
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(store);
    const {
        isHistoryShopsModalOpened,
        isDataUpdated,
        key,
        isLoading,
    } = useSnapshot(dataStore);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const uuidUser = localStorage.getItem("uuidUser");
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);
    const [data, setData] = React.useState<any>({});
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    let urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
    let urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    const CountryList = (e: any) => {
        let listPays: any = [];

        if (e.e.selectedRow.Shop !== undefined) {
            listPays =
                e.e.selectedRow.Country.labels !== undefined
                    ? e.e.selectedRow.Country.labels
                    : [e.e.selectedRow.Country];
        } else {
            listPays =
                e.e.selectedRow.Country.labels !== undefined
                    ? e.e.selectedRow.Country.labels
                    : e.e.selectedRow.Pays.labels !== undefined
                    ? e.e.selectedRow.Pays.labels
                    : e.e.selectedRow.Pays !== undefined
                    ? e.e.selectedRow.Pays
                    : "";
        }

        return (
            <CustomColumnDisplay
                text={`${
                    e.e.selectedRow.subRows !== undefined
                        ? Array.isArray(listPays)
                            ? listPays.join(",")
                            : " -- "
                        : listPays
                }`}
            />
        );
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Shop"),
            customJsx: (e: any) => {
                return <span>{e.selectedRow["Shop"]}</span>;
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay text={e.selectedRow["Display name"]} />
                );
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("Payment method"),
            customJsx: (e: any) => <PaymentMethodRow element={e} />,
            filterName: t("Payment method"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Sales support"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Sales support"]}
                    />
                );
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Address"),
            customJsx: (e: any) => {
                return <CustomColumnDisplay text={e.selectedRow["Address"]} />;
            },
        },
        {
            indexOFColumn: 6,
            columnName: t("Postal code"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay text={e.selectedRow["Postal code"]} />
                );
            },
        },
        {
            indexOFColumn: 7,
            columnName: t("Country"),
            customJsx: (e: any) => {
                return <CountryList e={e} />;
            },
        },
        {
            indexOFColumn: 9,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    selectedRowData={e}
                    isArchivedPaymentMethodsClicked={
                        isArchivedPaymentMethodsClicked
                    }
                    isArchivedValidationModalOpened={
                        isArchivedValidationModalOpened
                    }
                    setIsArchivedValidationModalOpened={
                        setIsArchivedValidationModalOpened
                    }
                    setSelectedRows={setSelectedRows}
                />
            ),
        },
    ];
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    async function handleArchivePaymentMethod(selectedRowData: any) {
        setIsLoading(true);
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.id]: {
                    designation: selectedRowData[t("Payment method")],
                    currency: selectedRowData.Devise,
                    display: selectedRowData[t("Display name")],
                    shopId: selectedRowData.idShop,
                    saleSupport: selectedRowData[t("Support de vente")],
                    country: selectedRowData.Pays,
                    tag: selectedRowData.tag,
                    isActive: false,
                    isArchived: true,
                    languages: selectedRowData.languages,
                    advancedDisplayName: selectedRowData.advancedDisplayName,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        toast.success(
                            `${t(
                                "The Payment method is successfully archived"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsValidateClicked(false);
                        setIsArchivedValidationModalOpened(false);
                        setIsLoading(false);

                        const item = itemsDisable.find(
                            (item: any) =>
                                item.id === selectedRows[0].id &&
                                item.shopId === selectedRows[0].idShop
                        );
                        if (item !== undefined) {
                            const item = itemsDisable.filter(
                                (item: any) =>
                                    !(
                                        item.id === selectedRows[0].id &&
                                        item.shopId === selectedRows[0].idShop
                                    )
                            );
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                    })
            );
        } catch (error) {
            toast.error(`${t("There's an erroraa")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        let array: any = [];
        let localShop: any = [];

        selectedRows.forEach((el: any) => {
            if (el.Boutique !== "" && el.Boutique !== undefined)
                localShop.push({
                    id: el.idShop,
                    label: el.Boutique,
                    pays: el.Pays,
                });
        });
        setLocalShop(localShop);
        let associatedPaymentMethod: any[] = [];
        selectedRows.forEach((element: any) => {
            if (element["Boutique"] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.idShop]: el.id });
                    associatedPaymentMethod.push(el);
                });
            } else {
                array.push({ [element.idShop]: element.id });
            }
        });
        setAssociatedPaymentMethods(associatedPaymentMethod);
        setShopsSelectedRow(selectedRows);
        setSelectedPaymentMethods(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows]);

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el.Boutique !== "" && el.Boutique !== undefined)
                localShop.push(el.Boutique);
        });

        setIsShopSelected(localShop.length >= 1);

        let localArchivedPaymentMethod: number = 0;
        let localActivePaymentMethod: number = 0;

        if (selectedRows.length > 0) {
        }

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element.Boutique !== undefined) {
                    localActivePaymentMethod +=
                        element.paymentMethodsNumber.activated;
                    localArchivedPaymentMethod +=
                        element.paymentMethodsNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el.Boutique).length > 0) {
                setShopsNumberActive(localActivePaymentMethod);
                setShopsNumberArchived(localArchivedPaymentMethod);
            } else {
                setShopsNumberActive(
                    data.othersData !== undefined
                        ? data.othersData.activated
                        : 0
                );
                setShopsNumberArchived(
                    data.othersData !== undefined ? data.othersData.archived : 0
                );
            }
        } else {
            setShopsNumberActive(
                data.othersData !== undefined ? data.othersData.activated : 0
            );
            setShopsNumberArchived(
                data.othersData !== undefined ? data.othersData.archived : 0
            );
        }

        setShopsPaymentMethodsData(data.data);
    }, [selectedRows, data]);

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(selectedRows[0]);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, selectedRows]);

    return (
        <React.Fragment>
            <DynamicTable
                key={key}
                requestHeader={{ "Accept-Language": i18nextLng }}
                url={isArchivedPaymentMethodsClicked ? urlArchive : urlActive}
                canExpand
                canSelect
                setData={setData}
                customSelect
                setSelectedRows={setSelectedRows}
                showGlobalFilter
                setLocalFilterActive={setLocalFilterActive}
                filterActive={filterActive}
                canMovedCheckboxLeftOnExpand
                customJsxSideFilterButton={
                    <CustomSideFilter
                        isShopSelected={isShopSelected}
                        selectedRows={selectedRows}
                        setIsAssociateModalOpened={setIsAssociateModalOpened}
                        setLocalFilterActive={setLocalFilterActive}
                        isAssociateModalOpened={isAssociateModalOpened}
                        isArchivedPaymentMethodsClicked={
                            isArchivedPaymentMethodsClicked
                        }
                        setIsArchivedPaymentMethodsClicked={
                            setIsArchivedPaymentMethodsClicked
                        }
                        setLimit={setToastLimit}
                    />
                }
                canResize
                actionColumn={ActionColumn}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={
                    setIsDataUpdated as
                        | React.Dispatch<React.SetStateAction<number | boolean>>
                        | undefined
                }
                dataIsUpdated={isDataUpdated}
                showFilter
                elevationTable={1}
                minHeight={oneShop ? "500px" : "450px"}
                maxHeight={oneShop ? "500px" : "450px"}
                name="Choice"
            />
            {isAssociateModalOpened ? (
                <PaymentModalAssociateModal
                    setToastLimit={setToastLimit}
                    selectedShopsRows={selectedRows}
                    setIsAssociateModalOpened={setIsAssociateModalOpened}
                    isAssociateModalOpened={isAssociateModalOpened}
                />
            ) : null}

            {isHistoryShopsModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryShopsModalOpened}
                    isModalOpened={isHistoryShopsModalOpened}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
            <NProgressWrapper isLoading={isLoading} />
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
