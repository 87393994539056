import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";

import { store, setIsCreatedCoping } from "../../../store/project";

import { capitalize } from "@helpers/general";
import CardFooterCreatedCoping from "./CardFooterCreatedCoping";
import moment from "moment";

type ModalCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
    dataProject: any;
    setSelectedRows: Function;
};

export default function ModalCreatedCoping({
    rowData,
    setIsDataUpdated,
    shopId,
    dataProject,
    setSelectedRows,
}: ModalCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping } = useSnapshot(store);
    const [designation, setDesignation] = React.useState(
        `${rowData[t("Designation")]}_${t("Copy")}_${moment().format(
            "DD/MM/YY - HH:mm:ss"
        )}`
    );
    return (
        <React.Fragment>
            <Modal centered isOpen={isCreatedCoping}>
                <ModalHeader
                    toggle={() => {
                        setIsCreatedCoping(false);
                        setSelectedRows([]);
                    }}
                >
                    <StyledH2>{capitalize(t("Project duplication"))}</StyledH2>
                </ModalHeader>
                <ModalBody
                    style={{ font: "normal normal 400 18px/30px Segoe UI" }}
                >
                    {t("Please enter the name of the new template to create")}.
                    <AvForm>
                        <StyledLabel className="mt-3" htmlFor="example-input">
                            {t("Designation")} Template
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                            }}
                            value={designation}
                        />
                    </AvForm>
                </ModalBody>
                <CardFooterCreatedCoping
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    shopId={shopId}
                    dataProject={dataProject}
                    setSelectedRows={setSelectedRows}
                    designation={designation}
                />
            </Modal>
        </React.Fragment>
    );
}
