import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import PaymentMethods from "./PaymentMethods";
import ShopsPaymentMethod from "./ShopsPaymentMethod";
import { StyledTabs } from "./StyledTabs";

import "./index.css";

function LocalPaymentMethod() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);

    const [
        isArchivedPaymentMethodsClicked,
        setIsArchivedPaymentMethodsClicked,
    ] = React.useState<boolean>(false);

    const [toastLimit, setToastLimit] = React.useState<number>(1);

    const tab = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <PaymentMethods
                            setToastLimit={setToastLimit}
                            isArchivedPaymentMethodsClicked={
                                isArchivedPaymentMethodsClicked
                            }
                            setIsArchivedPaymentMethodsClicked={
                                setIsArchivedPaymentMethodsClicked
                            }
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Payment method")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ShopsPaymentMethod
                            isArchivedPaymentMethodsClicked={
                                isArchivedPaymentMethodsClicked
                            }
                            setIsArchivedPaymentMethodsClicked={
                                setIsArchivedPaymentMethodsClicked
                            }
                            setToastLimit={setToastLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shops payment method")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={toastLimit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="mdr_txt_paymentMethod">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            }, 
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: isArchivedPaymentMethodsClicked
                                    ? t("Archived payment method").toUpperCase()
                                    : t("Payment method").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The different payment methods used by the store are configured in this section. It is important to note that they are configured based on the sales channel and country."
                    )}
                />
                {oneShop ? (
                    <PaymentMethods
                        isArchivedPaymentMethodsClicked={
                            isArchivedPaymentMethodsClicked
                        }
                        setIsArchivedPaymentMethodsClicked={
                            setIsArchivedPaymentMethodsClicked
                        }
                        setToastLimit={setToastLimit}
                    />
                ) : (
                    <MainColorTabsWrapper>{tab}</MainColorTabsWrapper>
                )}
            </div>
        </React.Fragment>
    );
}
export const PaymentMethod = React.memo(LocalPaymentMethod);
