import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { uuid } from "uuidv4";

import {
    store as generalSettingStore,
    setNumberArchived,
    setNumberActive,
    setIsActivation,
    setIsValidateArchiveOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost/index";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { languages } from "@constants/index";
import { generalConfigStore, setItemsDisable } from "@store";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setLanguage: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    isArchivedLanguagesClicked: boolean;
    setSelectedRows: Function;
    dataIsUpdated: boolean | number;
    setIsAddLanguageClicked: Function;
    isAddLanguageClicked: boolean;
    setIsAssociateModified: Function;
    dataAssociate: any;
    setSelectedElement: Function;
    setIsConsult: Function;
    setDataState: Function;
};

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setLanguage,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedLanguagesClicked,
    setIsAddLanguageClicked,
    isAddLanguageClicked,
    setIsAssociateModified,
    dataAssociate,
    setSelectedElement,
    setIsConsult,
    setDataState,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsLanguagesData,
        archivedLanguageFranchise,
        isActionsClicked,
        isEdited,
        modify,
        languagesData,
        isLoading,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, oneShop, operatorID } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    let dataArchive: any[] = [];

    const [languageState, setLanguageState] = React.useState<boolean>(
        e.selectedRow.isActive
    );

    const [activeclick, setActiveclick] = React.useState<boolean>(true);
    const uuidUser = localStorage.getItem("uuidUser");
    if (!oneShop) {
        dataAssociate = AssociateLangues();
        dataArchive = ArchivedLanguages();
    } else {
        dataArchive = ArchivedLanguagesFranchise();
    }

    function AssociateLangues() {
        let associated: any[] = [e.selectedRow];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.selectedRow.Id) {
                        associated.push(el);
                    }
                });
            });
        }
        return associated;
    }

    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (archivedLanguageFranchise !== undefined) {
            archivedLanguageFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: false,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                    advancedDisplayName: e.selectedRow.advancedDisplayName,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        setActiveclick(true);

                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The language was successfully unarchived")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(uuid());
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    async function favoriteLanguage(isFavorite: boolean) {
        if (isLoading === false) {
            setIsLoading(true);
            toast.dismiss();
            let updatedLanguage: any = {};
            languagesData.forEach((language: any) => {
                let langueObject: any = JSON.parse(JSON.stringify(language));
                if (langueObject.Id === e.selectedRow.Id) {
                    Object.assign(updatedLanguage, {
                        [langueObject.Id]: {
                            id: langueObject.Id,
                            language: languages.find(
                                (lg: any) =>
                                    lg.value === langueObject.abbreviation
                            )?.label,
                            display_name: langueObject.display_name,
                            country: langueObject.country,
                            urlImage: langueObject.url_image,
                            isArchived: langueObject.isArchived,
                            isFavorite: isFavorite,
                            isActive: langueObject.isActive,
                            shopId: langueObject.shopId,
                            isActiveShop: langueObject.isActive,
                            abbreviation: langueObject.abbreviation,
                            advancedDisplayName:
                                langueObject.advancedDisplayName,
                        },
                    });
                } else {
                    Object.assign(updatedLanguage, {
                        [langueObject.Id]: {
                            id: langueObject.Id,
                            language: languages.find(
                                (lg: any) =>
                                    lg.value === langueObject.abbreviation
                            )?.label,
                            display_name: langueObject.display_name,
                            country: langueObject.country,
                            urlImage: langueObject.url_image,
                            isArchived: langueObject.isArchived,
                            isFavorite: false,
                            isActive: langueObject.isActive,
                            shopId: langueObject.shopId,
                            isActiveShop: langueObject.isActive,
                            abbreviation: langueObject.abbreviation,
                            advancedDisplayName:
                                langueObject.advancedDisplayName,
                        },
                    });
                }
            });
            let dataLanguage = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: updatedLanguage,
            };

            const updateFavorite = true;
            const data = new FormData();
            data.append(
                "dataLanguage",
                JSON.stringify({ dataLanguage, updateFavorite })
            );
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
            const requestOptions = {
                method: "POST",
                body: data,
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                isFavorite
                                    ? `${
                                          dataAssociate.length > 1
                                              ? t(
                                                    "Franchise favorite state has been successfully activated"
                                                )
                                              : t(
                                                    "Favorite state has been enabled successfully"
                                                )
                                      }`
                                    : `${
                                          dataAssociate.length > 1
                                              ? t(
                                                    "Franchise favorite state has been successfully deactivated"
                                                )
                                              : t(
                                                    "Favorite state has been disabled successfully"
                                                )
                                      }`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsLoading(false);
                            if (dataAssociate.length > 1) {
                                setIsActivation(false);
                                setIsAssociateModified(true);
                            } else {
                                setDataIsUpdated(!dataIsUpdated);
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }

    async function enableLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === e.selectedRow.abbreviation
                    )?.label,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    abbreviation: e.selectedRow.abbreviation,
                    advancedDisplayName: e.selectedRow.advancedDisplayName,
                },
            },
        };
        const isStateChange = true;
        const data = new FormData();
        data.append(
            "dataLanguage",
            JSON.stringify({ dataLanguage, isStateChange })
        );
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setDataIsUpdated(!dataIsUpdated);
                        toast.success(
                            languageState
                                ? `${t(
                                      "The language was successfully deactivated"
                                  )}`
                                : `${t(
                                      "The language was successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (!languageState === false) {
                            const item = [
                                ...JSON.parse(JSON.stringify(itemsDisable)),
                                {
                                    id: e.selectedRow.Id,
                                    state: !languageState,
                                    shopId: e.selectedRow.shopId,
                                },
                            ];
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        } else if (!languageState === true) {
                            const item = itemsDisable.filter(
                                (item: any) =>
                                    !(
                                        item.id === e.selectedRow.Id &&
                                        item.shopId === e.selectedRow.shopId
                                    )
                            );
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function ArchivedLanguages() {
        let idsAssociated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function isLanguageConsumed() {
        toast.dismiss();
        let isLanguageUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&type=languages`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) => el.id === e.selectedRow.Id
                        )
                    ) {
                        isLanguageUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isLanguageUsed;
    }
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setLanguage(e.selectedRow);
                                                setIsAddLanguageClicked(true);
                                                setSelectedElement(e);
                                            });
                                        } else {
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                            setLanguage(e.selectedRow);
                                            setIsAddLanguageClicked(true);
                                            setSelectedElement(e);
                                        }
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked ||
                                    e.selectedRow.isFavorite === true
                                        ? ""
                                        : t("Enable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (
                                                e.selectedRow.isFavorite ===
                                                false
                                            ) {
                                                setIsLoading(true);
                                                if (isEdited === true) {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddLanguageClicked(
                                                            false
                                                        );
                                                        if (
                                                            e.selectedRow
                                                                .isActive ===
                                                                false &&
                                                            oneShop &&
                                                            e.selectedRow
                                                                .isFavorite ===
                                                                false
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Please note, you cannot activate the favorite state for a disabled language"
                                                                )
                                                            );
                                                        } else {
                                                            setRowData({
                                                                ...e.selectedRow,
                                                                isFavorite: !e
                                                                    .selectedRow
                                                                    .isFavorite,
                                                            });
                                                            favoriteLanguage(
                                                                !e.selectedRow
                                                                    .isFavorite
                                                            );
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            setLanguage({
                                                                ...e.selectedRow,
                                                                isFavorite: !e
                                                                    .selectedRow
                                                                    .isFavorite,
                                                            });

                                                            setSelectedElement(
                                                                e
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddLanguageClicked(
                                                        false
                                                    );
                                                    setIsLoading(false);
                                                    setLanguage({
                                                        ...e.selectedRow,
                                                        isFavorite: !e
                                                            .selectedRow
                                                            .isFavorite,
                                                    });
                                                    if (
                                                        e.selectedRow
                                                            .isActive ===
                                                            false &&
                                                        oneShop &&
                                                        e.selectedRow
                                                            .isFavorite ===
                                                            false
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot activate the favorite state for a disabled language"
                                                            )
                                                        );
                                                    } else {
                                                        setRowData({
                                                            ...e.selectedRow,
                                                            isFavorite: !e
                                                                .selectedRow
                                                                .isFavorite,
                                                        });
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        favoriteLanguage(
                                                            !e.selectedRow
                                                                .isFavorite
                                                        );
                                                        setSelectedElement(e);
                                                    }
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            e.selectedRow.isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "lan_icn_FRlike",
                                            {
                                                pointer__clz:
                                                    e.selectedRow.isFavorite ===
                                                    false,
                                            },
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            },
                                            oneShop ? "mr-2" : ""
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked ? "" : t("Edit")
                                }
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setLanguage(e.selectedRow);
                                                    setIsAddLanguageClicked(
                                                        true
                                                    );
                                                    setSelectedElement(e);
                                                });
                                            } else {
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setLanguage(e.selectedRow);
                                                setIsAddLanguageClicked(true);
                                                setSelectedElement(e);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isArchivedLanguagesClicked
                                            ? ""
                                            : languageState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={languageState}
                                            onChange={async () => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );

                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddLanguageClicked(
                                                            false
                                                        );
                                                        if (
                                                            e.selectedRow
                                                                .isFavorite ===
                                                            true
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Please note, you cannot deactivate a favorite language"
                                                                )
                                                            );
                                                        } else if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            setIsActivation(
                                                                true
                                                            );
                                                            setIsAssociateModified(
                                                                true
                                                            );
                                                        } else {
                                                            setLanguageState(
                                                                !languageState
                                                            );
                                                            enableLanguage();
                                                            setDataState({
                                                                id:
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                state: !languageState,
                                                            });
                                                        }

                                                        setSelectedElement(e);
                                                    });
                                                } else {
                                                    setIsAddLanguageClicked(
                                                        false
                                                    );
                                                    if (
                                                        e.selectedRow
                                                            .isFavorite === true
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot deactivate a favorite language"
                                                            )
                                                        );
                                                    } else if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        setIsActivation(true);
                                                        setIsAssociateModified(
                                                            true
                                                        );
                                                    } else {
                                                        setLanguageState(
                                                            !languageState
                                                        );
                                                        enableLanguage();
                                                        setDataState({
                                                            id:
                                                                e.selectedRow
                                                                    .Id,
                                                            state: !languageState,
                                                        });
                                                    }

                                                    setSelectedElement(e);
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz",
                                                {
                                                    "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isArchivedLanguagesClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={async () => {
                                                setIsLoading(true);
                                                if (isEdited === true) {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(false);
                                                    setActions(async () => {
                                                        setIsAddLanguageClicked(
                                                            false
                                                        );
                                                        if (
                                                            e.selectedRow
                                                                .isFavorite ===
                                                            true
                                                        ) {
                                                            ErrorToast(
                                                                t(
                                                                    "Please note, you cannot archive a favorite language"
                                                                )
                                                            );
                                                        } else if (
                                                            dataAssociate.length >
                                                            1
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            let isConsumed = await isLanguageConsumed();
                                                            if (
                                                                isConsumed ===
                                                                true
                                                            ) {
                                                                ErrorToast(
                                                                    t(
                                                                        "Please note, you cannot archive an element used in a template"
                                                                    )
                                                                );
                                                                setIsLoading(
                                                                    false
                                                                );
                                                            } else {
                                                                setSelectedRows(
                                                                    [
                                                                        e.selectedRow,
                                                                    ]
                                                                );
                                                                setIsValidateArchiveOpened(
                                                                    true
                                                                );
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    setIsLoading(false);
                                                    setIsAddLanguageClicked(
                                                        false
                                                    );
                                                    if (
                                                        e.selectedRow
                                                            .isFavorite === true
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot archive a favorite language"
                                                            )
                                                        );
                                                    } else if (
                                                        dataAssociate.length > 1
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        let isConsumed = await isLanguageConsumed();
                                                        if (
                                                            isConsumed === true
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores.111"
                                                                )!}`
                                                            );
                                                        } else {
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsValidateArchiveOpened(
                                                                true
                                                            );
                                                        }
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                {
                                                    "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            onClick={() => {
                                                setIsLoading(true);
                                                setRowData(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    setActiveclick(false);
                                                    activeLanguage();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        !activeclick ||
                                                        isLoading,
                                                },
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsAddLanguageClicked(false);
                                            setIsEdited(false);
                                            setSelectedRows([e.selectedRow]);
                                            setRowData(e.selectedRow);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        });
                                    } else {
                                        setIsAddLanguageClicked(false);
                                        setSelectedRows([e.selectedRow]);
                                        setRowData(e.selectedRow);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
