import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    setActiveSubTab,
    setIsWaysTabActive,
    generalConfigStore,
    computedStore,
    designStore,
    setIsAllergenActive,
    setIsCustomerAccountActive,
    setIsOrderTakingTopBannerActive,
    setIsOrderTakingTagsActive,
    setIsOrderTakingCategoryTitleActive,
    setIsOrderTakingProductInformationActive,
    setIsCaloryActive,
    setIsPRMActive,
    setIsHelpActive,
    setSelectedSaleModeValue,
    setIsConfigured,
    setUserAccountOrderTakingMessageItems,
    setIsUserAccountOrderTakingActive,
    setDefaultActiveStateScreenItems,
    setDefaultStandbyStateScreenItems,
    setIsStateChanged,
    setConfigOptionsCustomerAccount,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingPromoCodeActive,
    setIsCommentArticleActive,
    setIsCommentRecoveryActive,
    setIsFreeCommentActive,
    setFiles,
    setIsInformationModesEmpty,
    store,
    setIsShopCartDetailsActive,
    setDesignCategories,
    setIsNutriscoreActive,
    setGeneralTransition,
    setIsOrderTakingCaloryActive,
    setIsSpecificTransitionChosen,
    setProject,
    setIsShownProductCompositionDescription,
    setIsConfirmationPopupForDeletingAnArticleActive,
    setIsCategoriesScrollActive,
    setProductAnimation,
    setIsConfirmationEmailActive,
    setIsEmailActive,
    setIsUnauthenticatedEmailActive,
    setIsPaperlessTicketActive,
    setItems,
    setAuthenticationModesOptions,
} from "@store";
import kioskSettingStore from "@pages/GeneralConfig/store";

import { AdvertisingScreen } from "@pages/GeneralConfig/AdvertisingScreen";
import { Design } from "@pages/GeneralConfig/Design";
import { InformationModes } from "@pages/GeneralConfig/InformationModes";
import { CustomerAccount } from "@pages/GeneralConfig/CustomerAccount";
import { Languages } from "@pages/GeneralConfig/Languages";
import { Options } from "@pages/GeneralConfig/Options";
import { OrderTaking } from "@pages/GeneralConfig/OrderTaking";
import { MeansOfPayment } from "@pages/GeneralConfig/MeansOfPayment";
import { SalesMethods } from "@pages/GeneralConfig/SalesMethods";
import { TemplateSelection } from "@pages/GeneralConfig/TemplateSelection";
import { VariousTabs } from "@pages/GeneralConfig/Various/VariousTabs";
import { Ticket } from "@pages/GeneralConfig/Ticket";
import { Ways } from "@pages/GeneralConfig/Ways";
import { HoursOfUse } from "@pages/GeneralConfig/HoursOfUse";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { AuthenticationMode } from "@pages/GeneralConfig/CustomerAccount/AuthenticationMode";

import "./index.css";
import { store as storeGlobal } from "@components/VerticalLayout/store";
import { GODIA_LOYALTY, HAS_NO_LOYALTY } from "@constants/index";

type TabsMenuType = {
    // eslint-disable-next-line
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};

export function ModalContentWrapper(): JSX.Element {
    const { t } = useTranslation();
    let tabs: TabsMenuType[] = React.useMemo(() => [], []);
    const { mainColor } = useSnapshot(store);
    const {
        isWaysTabActive,
        chosenTemplate,
        activeSubTab,
        isCustomerAccountActive,
        project: { template },
        project,
        templates,
        isInformationModesEmpty,
        isConfigured,
        transitions,
        generalChosenTransition,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);
    const { elements } = useSnapshot(computedStore);
    const { activeSlideIndex, activePageName } = useSnapshot(designStore);
    const { fideltyType } = useSnapshot(storeGlobal);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        isKioskSetting ? true : false
    );

    if (isConfigured === true) {
        tabs = !isKioskSetting
            ? [
                  {
                      title: t("Template Selection"),
                      content: <TemplateSelection />,
                      customClassName: "pme_txt_menuTemplateChoice",
                  },
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "pme_txt_menuConfiguration",
                      subMenu: [
                          {
                              title: t("Options"),
                              content: <Options />,
                              customClassName: "pme_txt_menuOptions",
                          },
                          {
                              title: t("Advertising screen"),
                              content: <AdvertisingScreen />,
                              customClassName: "pme_txt_menuAdversitingScreen",
                          },
                          {
                              title: t("Sale Mode"),
                              content: <SalesMethods />,
                              customClassName: "pme_txt_menuSaleMode",
                          },
                          {
                              title: t("Means of payment"),
                              content: <MeansOfPayment />,
                              customClassName: "pme_txt_menuPaymentMethod",
                          },
                          {
                              title: t("Information modes"),
                              content: <InformationModes />,
                              customClassName: "pme_txt_menuInformationMode",
                          },
                          {
                              title: t("Loyalty account"),
                              content: <CustomerAccount />,
                              customClassName: "pme_txt_menuCustomerAccount",
                          },
                          {
                              title: t("Order taking"),
                              content: <OrderTaking />,
                              customClassName: "pme_txt_menuOrderTaking",
                          },
                          {
                              title: t("Languages"),
                              content: <Languages />,
                              customClassName: "pme_txt_menuLanguages",
                          },
                          {
                              title: t("Dematerialized ticket"),
                              content: <Ticket />,

                              customClassName: "pme_txt_ticket",
                          },
                          {
                              title: t("Various"),
                              content: <VariousTabs />,
                              customClassName: "pme_txt_menuDrivers",
                          },
                      ],
                  },
                  {
                      title: t("Ways"),
                      content: <Ways />,
                      customClassName: "pme_txt_menuWays",
                  },
                  {
                      title: t("Advanced settings"),
                      content: <Design />,
                      customClassName: "pme_txt_menuDesign",
                  },
              ]
            : [
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "",
                      subMenu: [
                          {
                              title: t("Options"),
                              content: <Options />,
                              customClassName: "",
                          },
                          {
                              title: t("Advertising screen"),
                              content: <AdvertisingScreen />,
                              customClassName: "",
                          },
                          {
                              title: t("Sale Mode"),
                              content: <SalesMethods />,
                              customClassName: "",
                          },
                          {
                              title: t("Means of payment"),
                              content: <MeansOfPayment />,
                              customClassName: "",
                          },
                          {
                              title: t("Information modes"),
                              content: <InformationModes />,
                              customClassName: "",
                          },
                          {
                              title: t("Customer account"),
                              content: <AuthenticationMode />,
                              customClassName: "",
                          },
                          {
                              title: t("Ticket"),
                              content: <Ticket />,
                              customClassName: "",
                          },
                      ],
                  },
                  {
                      title: (
                          <span>
                              <TimePastSvgIcon
                                  height={15}
                                  width={15}
                                  fill="white"
                                  style={{
                                      marginRight: "10px",
                                      marginBottom: "3px",
                                  }}
                              />
                              {t("Hours of use")}
                          </span>
                      ),
                      content: <HoursOfUse />,
                      customClassName: "",
                  },
              ];
    } else {
        tabs = [
            {
                title: t("Template Selection"),
                content: <TemplateSelection />,
                customClassName: "pme_txt_menuTemplateChoice",
            },
            {
                title: t("Configuration"),
                content: "",
                customClassName: "pme_txt_menuConfiguration",
            },
            {
                title: t("Ways"),
                content: "",
                customClassName: "pme_txt_menuWays",
            },
            {
                title: t("Advanced settings"),
                content: "",
                customClassName: "pme_txt_menuDesign",
            },
        ];
    }

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    React.useEffect(() => {
        if (project.template.id !== "") {
            setIsWaysTabActive(
                tabs.findIndex((item) => {
                    return item.title === t("Ways");
                }) === activeTab
            );
            setIsConfigured(true);
        }
    }, [tabs, activeTab, project.template.id, t]);

    React.useEffect(() => {
        setActiveTab(0);
        setActiveSubTab(0);
    }, []);

    React.useEffect(() => {
        if (project.template.id !== "") {
            let templateSelected = templates.filter(
                // eslint-disable-next-line
                (defaultTemplate: any) =>
                    defaultTemplate.id === project.template.id
            )[0];

            if (
                (templateSelected.pages.elements
                    .standbyScreen as StandbyScreenPageType) !== undefined
            ) {
                setDefaultActiveStateScreenItems(
                    (templateSelected.pages.elements
                        .standbyScreen as StandbyScreenPageType).items
                );
            } else {
                setDefaultActiveStateScreenItems(
                    (templateSelected.pages.ways
                        .standbyScreen as StandbyScreenPageType).items
                );
            }

            setDefaultStandbyStateScreenItems(
                (templateSelected.content
                    .standbyStateScreen as StandbyStateScreenItemsType)
                    .items as StandbyScreenItemType[]
            );
        }
        // eslint-disable-next-line
    }, [project.template.id]);

    const getActiveStatus = (title: string) => {
        return (project.template.content.options as OptionsType).items.filter(
            (item) => item.title === title
        )[0].active;
    };

    React.useEffect(() => {
        if (project.template.id !== "") {
            if (
                elements.length > 0 &&
                elements[activeSlideIndex] !== undefined
            ) {
                setIsAllergenActive(getActiveStatus("Allergen"));

                setIsCaloryActive(getActiveStatus("Calorie"));

                setIsPRMActive(getActiveStatus("PRM/Child"));

                setIsHelpActive(getActiveStatus("Ask for help"));

                const selectedSaleMethodDeliveryExist = (project.template
                    .content
                    .salesMethods as ProjectContentItemType).items.filter(
                    // eslint-disable-next-line
                    (sale: any) => sale.name === "Delivery"
                )[0];
                if (fideltyType === HAS_NO_LOYALTY) {
                    setIsCustomerAccountActive(false);
                    setConfigOptionsCustomerAccount(false);
                } else {
                    if (
                        selectedSaleMethodDeliveryExist !== undefined &&
                        selectedSaleMethodDeliveryExist.active === true
                    ) {
                        setIsCustomerAccountActive(true);

                        setIsStateChanged(true);
                        setConfigOptionsCustomerAccount(true);
                    } else {
                        setIsCustomerAccountActive(
                            (project.template.content
                                .options as OptionsType).items.filter(
                                (item) => item.title === "Customer account"
                            )[0].active
                        );
                    }
                }

                setIsOrderTakingTopBannerActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).topBanner.active
                );

                setIsOrderTakingTagsActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct.tags
                );

                setIsOrderTakingSelectedCategoryTitleActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .selectedCategoryTitle
                );

                setIsOrderTakingPromoCodeActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.promoCodeActive
                );

                setIsOrderTakingCategoryTitleActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct.categoryTitle
                );

                setIsOrderTakingProductInformationActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .productAccessIcon.active
                );

                setIsShopCartDetailsActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isShopCartDetailsActive
                );

                setDesignCategories(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .designCategories
                );

                setIsOrderTakingCaloryActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct.calorieIcon
                        .active
                );

                setIsShownProductCompositionDescription(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isShownProductCompositionDescription
                );

                setIsConfirmationPopupForDeletingAnArticleActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isConfirmationPopupForDeletingAnArticleActive
                );

                setIsCategoriesScrollActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isCategoriesScrollActive
                );

                setProductAnimation(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).compositeProduct.productAnimation
                );

                if (
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).compositeProduct.isMinMaxShown ===
                    undefined
                ) {
                    setProject({
                        ...project,
                        template: {
                            ...project.template,
                            pages: {
                                ...project.template.pages,
                                ways: {
                                    ...project.template.pages.ways,
                                    orderTaking: {
                                        ...(project.template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType),
                                        compositeProduct: {
                                            ...(project.template.pages.ways[
                                                "orderTaking"
                                            ] as OrderTakingPageType)
                                                .compositeProduct,
                                            isMinMaxShown: true,
                                        },
                                    },
                                },
                            },
                        },
                    });
                }

                if (
                    (project.template.pages.subSteps[
                        "accountFidelite"
                    ] as PageType).registerForm === undefined
                ) {
                    setProject({
                        ...project,
                        template: {
                            ...project.template,
                            pages: {
                                ...project.template.pages,
                                subSteps: {
                                    ...project.template.pages.subSteps,
                                    accountFidelite: {
                                        ...(project.template.pages.subSteps[
                                            "accountFidelite"
                                        ] as PageType),
                                        registerForm: {
                                            name: {
                                                active: true,
                                                type: "string",
                                                group: 1,
                                            },
                                            email: {
                                                active: true,
                                                type: "email",
                                                group: 2,
                                            },
                                            country: {
                                                active: true,
                                                type: "string",
                                                group: 3,
                                            },
                                            password: {
                                                active: true,
                                                type: "password",
                                                group: 4,
                                            },
                                            firstName: {
                                                active: true,
                                                type: "string",
                                                group: 1,
                                            },
                                            postalCode: {
                                                active: true,
                                                type: "number",
                                                group: 3,
                                            },
                                            phoneNumber: {
                                                active: true,
                                                type: "number",
                                                group: 0,
                                            },
                                            confirmPassword: {
                                                active: true,
                                                type: "password",
                                                group: 4,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    });
                }

                if (
                    project.files.find(
                        // eslint-disable-next-line
                        (element: any) =>
                            element.id ===
                            "c925473d-03e5-46ad-839a-58489e20e7c4"
                    ) === undefined
                ) {
                    const nfiles = [
                        ...project.files,
                        {
                            id: "c925473d-03e5-46ad-839a-58489e20e7c4",
                            key: "topBannerBackground",
                            name: "connextionSubSteps",
                            content: "",
                            subName: "accountFidelite",
                            defaultImage: "",
                            defaultContent: "",
                        },
                    ];

                    setFiles(nfiles);
                    setProject({
                        ...project,
                        files: nfiles,
                    });
                }

                setIsNutriscoreActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isNutriscoreActive
                );

                const filterInformationModeInSaleMethod = (project.template
                    .content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (saleMethod) => {
                        return (
                            saleMethod.name !== "Retrait C&C" &&
                            (saleMethod.informationModes as InformationModesContentType)
                                .items.length !== 0 &&
                            saleMethod.active === true
                        );
                    }
                );

                if (
                    (project.template.content
                        .informationModes as ProjectContentItemType).items
                        .length === 0 ||
                    filterInformationModeInSaleMethod.length === 0
                ) {
                    setIsInformationModesEmpty(true);
                } else {
                    setIsInformationModesEmpty(false);

                    if (
                        activeSubTab !== 4 &&
                        activePageName !== "informationModes"
                    ) {
                        setSelectedSaleModeValue(
                            (project.template.content
                                .salesMethods as ProjectContentItemType).items.filter(
                                (saleMethod) => {
                                    return (
                                        saleMethod.name !== "Retrait C&C" &&
                                        (saleMethod.informationModes as InformationModesContentType)
                                            .items.length !== 0 &&
                                        saleMethod.active === true
                                    );
                                }
                            )[0].name
                        );
                    }
                }

                if (
                    (project.template.content
                        .options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active === false
                ) {
                    setIsUserAccountOrderTakingActive(false);
                    setUserAccountOrderTakingMessageItems(
                        ((project.template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).header
                            .userAccount as UserAccountType).languages
                    );
                } else {
                    setIsUserAccountOrderTakingActive(true);
                    setUserAccountOrderTakingMessageItems(
                        ((project.template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).header
                            .userAccount as UserAccountType).languages
                    );
                }
                setIsCommentArticleActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isCommentArticleActive
                );
                setIsFreeCommentActive(
                    (project.template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).orderTakingProduct
                        .isFreeCommentActive
                );
                setIsCommentRecoveryActive(false);

                if (
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.breadcrumbTrail === undefined
                ) {
                    setProject({
                        ...project,
                        template: {
                            ...project.template,
                            pages: {
                                ...project.template.pages,
                                ways: {
                                    ...project.template.pages.ways,
                                    orderTaking: {
                                        ...(project.template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType),
                                        orderTakingProduct: {
                                            ...(project.template.pages.ways[
                                                "orderTaking"
                                            ] as OrderTakingPageType)
                                                .orderTakingProduct,
                                            breadcrumbTrail: (project.template
                                                .pages.ways[
                                                "orderTaking"
                                            ] as OrderTakingPageType)
                                                .orderTakingProduct
                                                .selectedCategoryTitle,
                                        },
                                    },
                                },
                            },
                        },
                    });
                }

                const isAnimationSame = Object.keys(
                    project.template.pages.ways
                ).map((key: any) => {
                    return (project.template.pages.ways[key] as
                        | PageType
                        | ConsommationPageType
                        | PaymentPageType
                        | PrinterOptionsPageType).animation;
                });

                if (
                    isAnimationSame.every(
                        (currentValue) => currentValue === isAnimationSame[0]
                    )
                ) {
                    setGeneralTransition(transitions[isAnimationSame[0]]);
                    setIsSpecificTransitionChosen(false);
                } else {
                    setGeneralTransition({
                        prettyName: "",
                        timeout: 0,
                        className: "",
                    });
                    setIsSpecificTransitionChosen(true);
                }
            }
            // eslint-disable-next-line
        }
    }, [
        activeSlideIndex,
        elements,
        project.template.content.options,
        project.template.content.salesMethods,
        project.template.id,
        project.template.pages.ways,
        isKioskSetting,
        project.template.pages.elements.standbyScreen,
        project.template.content.standbyStateScreen,
        project.files,
        project.template.content.informationModes,
        project.template.content.saleMethods,
        transitions,
        generalChosenTransition,
        project.template.pages,
        activeSubTab,
        activePageName,
        project.template.pages.subSteps,
        project,
        template.pages.ways,
        fideltyType,
        project.template.pages.subSteps,
    ]);

    React.useEffect(() => {
        if (project.template.id !== "") {
            setFiles(project.files);

            if (
                ((project.template.content
                    .informationModes as ProjectContentItemType)
                    .items as ProjectMainContentItemType[]).length === 0 ||
                ((project.template.content
                    .informationModes as ProjectContentItemType)
                    .items as ProjectMainContentItemType[]).filter(
                    (item: ProjectMainContentItemType) => {
                        return ((project.template.content
                            .salesMethods as ProjectContentItemType)
                            .items as ProjectMainContentItemType[]).filter(
                            // eslint-disable-next-line
                            (saleMethod: any) => {
                                return (
                                    Object.keys(item.active).includes(
                                        saleMethod.name
                                    ) && saleMethod.active === true
                                );
                            }
                        );
                    }
                ).length === 0
            ) {
                setIsInformationModesEmpty(true);
            }

            if (
                (project.template.pages.ways[
                    "printerOptions"
                ] as PrinterOptionsPageType) !== undefined
            ) {
                setIsEmailActive(
                    ((project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType)
                        .printerOptionsConfig as PrinterOptionsConfigType)
                        .isEmailActive
                );
                setIsConfirmationEmailActive(
                    ((project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType)
                        .printerOptionsConfig as PrinterOptionsConfigType)
                        .isConfirmationEmailActive
                );
                setIsUnauthenticatedEmailActive(
                    ((project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType)
                        .printerOptionsConfig as PrinterOptionsConfigType)
                        .isUnauthenticatedEmailActive
                );
                setIsPaperlessTicketActive(
                    (project.template.pages.ways[
                        "printerOptions"
                    ] as PrinterOptionsPageType).isPaperlessTicketActive
                );
            } else if (
                (project.template.pages.elements[
                    "printerOptions"
                ] as PrinterOptionsPageType) !== undefined
            ) {
                setIsPaperlessTicketActive(false);
                setIsEmailActive(false);
                setIsConfirmationEmailActive(false);
                setIsUnauthenticatedEmailActive(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: mainColor }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "pb-1",
                                element.customClassName
                            )}
                        >
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    },
                                    {
                                        "disable-account-creation-items__clz":
                                            chosenTemplate.name === "",
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);

                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              disabled={
                                                  (item.title ===
                                                      t("Loyalty account") &&
                                                      !isCustomerAccountActive) ||
                                                  (item.title ===
                                                      t("Information modes") &&
                                                      isInformationModesEmpty)
                                              }
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",

                                                  {
                                                      "active sub-menu-wrapper-items-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  },

                                                  item.customClassName
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                className={classNames({
                    "px-5": !isWaysTabActive && activeTab !== 3,
                })}
                style={{
                    overflowY: isWaysTabActive ? "hidden" : "unset",
                }}
            >
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            key={index}
                            className={classNames("h-100", {
                                "design-active-tab__clz": activeTab === 3,
                            })}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
