import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as saleStore,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setIsLoading,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { setItemsDisable } from "@store";
type ConfirmationUpdateType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setData: Function;
    setLimit: Function;
};

export function ConfirmationUpdate({
    isModalOpened,
    setIsModalOpened,
    setData,
    setLimit,
}: ConfirmationUpdateType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const {
        localShop,
        isAssociateModalOpened,
        numberActiveMultiShop,
        selectedRows,
        ids,
        activeModeSale,
        rowsShop,
        message,
        modeOfSaleFavored,
        salesSupports,
    } = useSnapshot(saleStore);
    const [error] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function checkIfAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    async function associateModeOfSale() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        let isReassignment = false;
        let salesSupportInShops: boolean[] = [];
        let favoriteSelectedSaleSupport: any = [];
        let shopSelectedSaleSupport: any = [];
        for (let element of localShop) {
            let associated = checkIfAssociated(element);
            let objectData: any = {};
            if (selectedRows.length !== 0) {
                let selectedRow = [...selectedRows];
                selectedRow.forEach(() => {
                    favoriteSelectedSaleSupport.push(
                        modeOfSaleFavored.find(
                            (element: any) => element.status === true
                        )
                    );
                });

                if (favoriteSelectedSaleSupport !== undefined) {
                    let shopFavoriteSaleSupport: any = associated.find(
                        (el: any) => el.isFavorite === true
                    );
                    if (shopFavoriteSaleSupport !== undefined) {
                        let shopFavoriteSaleSupport: any = {
                            ...associated.find(
                                (el: any) => el.isFavorite === true
                            ),
                        };
                        shopFavoriteSaleSupport.isFavorite = false;
                        if (
                            selectedRows.find(
                                (el: any) =>
                                    el.Id === shopFavoriteSaleSupport.Id
                            ) === undefined
                        ) {
                            selectedRow.push(shopFavoriteSaleSupport);
                            shopSelectedSaleSupport.push(
                                shopFavoriteSaleSupport
                            );
                        }
                    }
                }

                selectedRow.forEach(async (el: any) => {
                    let salesSupportInShop: boolean = el.support_vente.some(
                        (r: any) =>
                            salesSupports
                                .find((el: any) => el.id === element)
                                .salesSupport.indexOf(r) >= 0
                    );
                    salesSupportInShops.push(salesSupportInShop);
                    let filteredSalesSupport: any = el.support_vente.filter(
                        (value: any) =>
                            salesSupports
                                .find((el: any) => el.id === element)
                                .salesSupport.includes(value)
                    );
                    let indexOfAssociated = associated.findIndex(
                        (elt: any) => elt.Id === el.Id
                    );
                    let indexOfActive = activeModeSale.findIndex(
                        (element: any) => element.Id === el.Id
                    );
                    let indexInFavoredModeOfSale = modeOfSaleFavored.findIndex(
                        (elt: any) => elt.id === el.Id
                    );

                    if (
                        (indexOfAssociated === -1 ||
                            (indexOfAssociated !== -1 &&
                                (activeModeSale[indexOfActive].state !==
                                    associated[indexOfAssociated].state ||
                                    modeOfSaleFavored[indexInFavoredModeOfSale]
                                        .status !==
                                        associated[indexOfAssociated]
                                            .isFavorite))) &&
                        salesSupportInShop
                    ) {
                        Object.assign(objectData, {
                            [el.Id]: {
                                designation: el["Mode of sale"],
                                tag: el.tag,
                                display_name: el.display_name,
                                support_vente: filteredSalesSupport,
                                isArchived: el.isArchived,
                                shopId: element,
                                state: activeModeSale.find(
                                    (element: any) => element.Id === el.Id
                                ).state,
                                isFavorite:
                                    modeOfSaleFavored.find(
                                        (element: any) => element.id === el.Id
                                    ) !== undefined
                                        ? modeOfSaleFavored.find(
                                              (element: any) =>
                                                  element.id === el.Id
                                          ).status
                                        : el.isFavorite,
                                isReassignment:
                                    associated.findIndex(
                                        (elt: any) => elt.Id === el.Id
                                    ) !== -1,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        });
                    }
                });

                let dataModeOfSale = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                //eslint-disable-next-line
                Object.values(objectData).forEach((elt: any) => {
                    if (elt.isReassignment === true) {
                        isReassignment = true;
                    }
                });
                localData.push(dataModeOfSale);
            }
        }

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }

        if (error === false) {
            setLimit(2);
            if (!salesSupportInShops.includes(false)) {
                const getItemsDisableUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(
                    `${t(
                        isReassignment
                            ? "The reassignment was successful"
                            : "The assignment was successful"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
            } else if (
                salesSupportInShops.includes(true) &&
                salesSupportInShops.includes(false)
            ) {
                const getItemsDisableUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(
                    `${t(
                        isReassignment
                            ? "The reassignment was successful"
                            : "The assignment was successful"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                setIsLoading(false);
            }

            setData(true);
            setNumberActiveMultiShop(
                numberActiveMultiShop +
                    selectedRows.length * localShop.length -
                    ids
            );
            setIsLoading(false);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        associateModeOfSale();
                        setIsModalOpened(!isModalOpened);
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
